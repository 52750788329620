@import "../gridstyles.module";

$table-font-size: 15px;

.table {
  max-width: 100%;
  overflow-y: visible;
  page-break-inside: avoid;
  font-size: $table-font-size;
  font-feature-settings: "tnum", "zero";

  // we want this but can't have it because it prevents focusing the table
  // &.editor table { user-select: none; }

  th,
  td {
    line-height: 1.3333;
  }

  .head {
    display: flex;
    flex-direction: row-reverse;
  }

  .caption {
    padding-bottom: .3em;
    flex: 1 1 80%;
  }

  .title, .subtitle {
    font-family: var(--chart-font);
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    text-align: left;
  }

  .title {
    margin-bottom: 1px;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
  }

  .filter {
    flex: 1 1 180px;
    min-width: 100px;
    max-width: 180px;
    align-self: flex-end;
    padding-bottom: 5px;

    .inputWrap {
      @include inputWrapperStyles();

      input[type="search"] {
        @include inputStyles();

        caret-color: currentColor;
      }
    }
  }

  table {
    clear: both;
    line-height: 1;
    border-collapse: collapse;
    font-family: var(--chart-font);
    margin: 0;
    width: 100%;
    overflow-y: auto;
  }

  .th {
    display: block;
    padding: 0.3333em .3em;
  }

  thead {
    position: sticky;
    top: 0;
    background: var(--background-color);
    padding-bottom: 1px;
    white-space: initial;
    z-index: 1;
  }

  tfoot {
    position: sticky;
    bottom: 0;
    background: var(--background-color);
    padding-bottom: 1px;
    white-space: initial;
    z-index: 1;
  }

  thead th,
  thead td {
    text-align: center;
    vertical-align: bottom;
    font-size: inherit;
    font-weight: 700;
  }

  tbody th {
    font-size: inherit;
    font-weight: 500;
  }

  thead th[colspan] {
    .th {
      border-bottom: 1px solid var(--table-line);
      margin-right: 4px;
      margin-left: 4px;
    }

    &:last-child .th {
      margin-right: 0;
    }

    &:first-child .th {
      margin-left: 0;
    }
  }

  .bottomRow {
    // Yes, this is an insane way to put a border on an element but
    // due to browser "bugs", both the THEAD bottom-border and the
    // bottom row bottom-border styles are not actually attached to
    // those element and thus scroll with the table rather than stay
    // with their container.
    // Additionally, the background has to be on the cells rather
    // than the THEAD because if you set it on the THEAD element
    // you get bizarre offset errors across cells with spans.
    background-position: bottom .5px center;
    background-size: 1px 1px;
    background-repeat: repeat-x;
    background-image: linear-gradient(0deg, var(--table-line), var(--table-line));
  }

  tbody.zebra tr:nth-child(2n+1) {
    background: var(--table-alt);
  }

  thead th.corner {
    padding: 0.3333em .7em;
  }

  tbody {
    td, th {
      position: relative;
      vertical-align: top;
      padding: 0.3333em .7em;
      border-bottom: 1px solid var(--table-line);
    }

    tr.withFooter:last-child {
      td, th {
        border-bottom: 0;
      }
    }
  }

  tfoot th,
  tfoot td {
    position: relative;
    vertical-align: top;
    padding: 0.3333em .7em;
    text-align: left;
    font-weight: 600;
    font-size: inherit;

    &.topRow {
      // same insane way as for bottomRow :)
      background-position: top .5px center;
      background-size: 1px 1px;
      background-repeat: repeat-x;
      background-image: linear-gradient(0deg, var(--table-line), var(--table-line));
    }
  }

  .cellStyles tbody th,
  .cellStyles tbody td {
    vertical-align: bottom;
  }

  // top-border for first row when there is no thead
  tbody:first-child th,
  tbody:first-child td {
    border-top: 1px solid var(--table-line);
  }

  tr {
    // because rows can collapse completly from merges
    // we need to give them a min height, and for tables
    // height is the min height property. 🙃
    height: 2.1334em;
  }

  &.compact {
    thead th.corner {
      padding: 0.3333em .4em;
    }

    tbody th,
    tbody td,
    tfoot th,
    tfoot td {
      padding: 0.1333em .4em;
    }

    tr {
      height: 1.6em;
    }
  }

  &.comfortable {
    tbody th,
    tbody td,
    tfoot th,
    tfoot td {
      padding: 0.6667em .7em;
    }

    tr {
      height: 2.6667em;
    }
  }

  .sortable * {
    cursor: pointer;
  }

  .sortable th > span {
    display: block;
    position: relative;
  }

  .sortable .wordJoin {
    display: inline;
    white-space: nowrap;
  }

  .sortable th span svg {
    margin-left: 5px;

    path {
      fill: var(--color-80);
    }

    path.active {
      fill: currentColor;
    }
  }

  .tableContainer {
    clear: both;
  }
}
