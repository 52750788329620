@import "../../gridstyles.module";

.inputWrap {
  @include inputWrapperStyles();

  display: inline-flex;

  input, select {
    @include inputStyles();
  }

  :global(.selection) & {
    opacity: .7;
  }

  select option {
    color: unset;
  }

  input {
    &[type="number"] {
      appearance: textfield;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    &::-webkit-calendar-picker-indicator {
      appearance: none;
      display: none;
      opacity: 0;
    }
  }
}

.small {
  --helper-width: 25px;
  --helper-height: 25px;
  --helper-inner-width: 16px;
  --helper-inner-height: 16px;
  --stepper-height: 16px;
}

.medium {
  --helper-width: 27px;
  --helper-height: 32px;
  --helper-inner-width: 18px;
  --helper-inner-height: 22px;
  --stepper-height: 26px;
}

.large {
  --helper-width: 29px;
  --helper-height: 40px;
  --helper-inner-width: 20px;
  --helper-inner-height: 28px;
  --stepper-height: 38px;
}
