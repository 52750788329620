.radio {
  .item {
    margin: 0 2px;
    display: block;
  }

  &:global(.txt_mode) {
    display: inline;

    :global(.label) {
      margin-right: 4px;
    }

    .item {
      display: inline;
    }
  }

  .baseFont {
    font-size: inherit;
  }
}

.noBreak {
  white-space: nowrap;
  padding-right: 6px;
}
