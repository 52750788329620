.tooltipContainer {
  display: inline-block;
  vertical-align: middle;
  line-height: 1px;
  height: 27px;
  font-size: inherit;
  font-family: var(--controls-font);

  .tooltipHelper {
    display: inline-block;
    border-radius: 26px;
    text-align: center;
    user-select: none;
    box-sizing: border-box;
    border: 1px solid transparent;
    font-size: 16px;
    min-width: 1em;
    margin: 0 0 0 1px;
    height: 1em;
    background: var(--color-90);

    &.len2 {
      padding: 0 .095em;
    }

    &.len3 {
      padding: 0 .3em;
    }

    span {
      display: block;
      font-size: .7em;
      height: 100%;
      width: 100%;
      line-height: 1.3;
    }

    &.filled {
      background-color: var(--checked-background);
      color: var(--checked-text);
    }

    &.emoji {
      background: unset;
      padding: 0;
      height: 1.3em;

      span {
        padding: 0;
        font-size: 1em;
      }
    }

    &.emoji.filled {
      background-color: var(--checked-background);
      padding: 0 .1em;
    }
  }
}
