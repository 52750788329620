$stripe-size: 1px;
$stripe-gap: 9px;

.dataerror {
  border: 1px dashed var(--color-50);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: var(--controls-font);
  font-size: 16px;
  position: relative;
  width: 100%;
  line-height: 1.3;
  color: var(--color-20);
  padding: 23px;
  cursor: default;
  display: flex;

  .title {
    font-weight: 500;
    display: block;
    white-space: nowrap;

    svg {
      vertical-align: middle;
    }

    .twrap {
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;

      span {
        margin-left: 10px;
      }
    }
  }

  .message {
    font-size: 14px;
    margin-top: 10px;
    color: var(--color-40);
    display: block;
  }

  .errorInner {
    display: inline-block;
    max-width: 100%;
  }

  &.canEdit {
    .title {
      font-size: 20px;
      flex-shrink: 1;
    }

    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &.small {
    .message { display: none; }
  }

  &.xsmall {
    .message { display: none; }
  }

  &.tiny {
    .message,
    .title .twrap span { display: none; }
  }
}

.dataerror.readOnly {
  align-items: flex-end;
  justify-content: flex-end;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent $stripe-gap,
    var(--color-90) $stripe-gap,
    var(--color-90) ($stripe-gap + $stripe-size)
  );

  &.small {
    text-align: center;
    align-items: center;
    justify-content: center;

    .message { display: none; }
  }

  &.xsmall {
    .message { display: none; }
  }

  &.tiny {
    text-align: center;
    align-items: center;
    justify-content: center;

    .message,
    .title .twrap span { display: none; }
  }
}
