.scrollAreaWrap {
  position: relative;
}

.scrollArea {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none; // older Firefox
  scrollbar-width: none;  // Firefox
  -ms-overflow-style: none;  // MSIE 10+

  &::-webkit-scrollbar { // webkit
    display: none;
  }
}

.scrollBarTrack {
  display: block;
  overflow: hidden;
  outline: none;
}

.scrollBarThumb {
  background: var(--color-80);
  display: block;
  position: absolute;
  border-radius: 4px;
  cursor: default;
}

.vertical {
  &.scrollBarTrack {
    width: 6px;
    margin-top: 0;
    position: absolute;
    right: -8px;
  }

  .scrollBarThumb {
    width: 6px;
    height: 50%;
    top: 0;
    right: 0;
  }

  .scrollBarThumb:hover,
  .scrollBarTrack:focus .scrollBarThumb {
    background: var(--color-60);
  }

  .scrollBarThumb div {
    position: absolute;
    margin-top: -6px;
    height: 18px;
    width: 100%;
  }
}

.horizontal {
  &.scrollBarTrack {
    height: 6px;
    margin-top: 2px;
  }

  .scrollBarThumb {
    height: 6px;
    width: 50%;
    bottom: 0;
    left: 0;
  }

  .scrollBarThumb:hover,
  .scrollBarTrack:focus .scrollBarThumb {
    background: var(--color-60);
  }

  .scrollBarThumb div {
    position: absolute;
    margin-top: -6px;
    height: 18px;
    width: 100%;
  }
}
