.header {
  display: flex;
  border-bottom: 1px solid var(--dp-header-border);
  padding-bottom: 0;
  margin-bottom: 4px;
}

.header .button {
  box-sizing: border-box;
  display: block;
}

.header > .button {
  padding: 2px 6px;
  flex-grow: 1;
  text-align: center;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
}

.header > .button:not(.disabled):hover {
  text-decoration: underline;
}

.header .disabled {
  color: var(--dp-disabled-text);
  cursor: default;
}

.header .prev,
.header .next {
  width: 30px;
  flex-grow: 0 !important;
}

.header svg {
  vertical-align: 5%;
  height: 8px;
}
