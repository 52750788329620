@import "../gridstyles.module";

.tangle {
  .outerValue {
    min-width: 100%;
    display: inline-block;
    position: relative;
  }

  .outerValue.inline {
    width: auto;
    display: inline;
    position: relative;
  }

  .value {
    border-radius: 0;
    position: relative;
    cursor: ew-resize;
    display: inline-block;
    min-width: 1rem;
    text-align: left;
    text-decoration: underline currentColor dotted 1px;
    text-underline-offset: 2px;
    touch-action: none;
  }

  &.disabled .value {
    cursor: default;
    text-decoration-line: none;
    color: var(--disabled-text);
  }

  &.invalid .value {
    cursor: not-allowed;
    color: var(--disabled-text);
  }
}

.tangle :global(.value) {
  position: relative;
}
