.kpi {
  border-radius: 0;
  font-size: inherit;

  .icon {
    display: inline-flex;
    padding-right: 4px;
  }

  .glyph {
    display: none;
  }
}

.kpiWrap svg {
  user-select: none;
}

// --- styles for "stacked" KPIs

.kpiBlock {
  display: inline-block;
  text-align: center;
  vertical-align: middle;

  .kpiWrap {
    display: flex;
    flex-direction: column;
  }

  .value {
    font-size: 1.68em;
    line-height: 36px;
    font-weight: 500;
    color: var(--text-color);
  }

  .changeWrap {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
}

// --- styles for inline KPIs

.kpiFlow {
  .kpiWrap {
    display: inline-flex;
    border-radius: 6px;
    border: 1px solid currentColor;
    padding: 0 12px;
    box-sizing: border-box;
    height: 1.26em;
    line-height: 1.2;
    vertical-align: baseline;
    margin-top: 2px;
    margin-bottom: -2px;
  }

  .value {
    font-weight: 500;
    color: var(--text-color);
  }

  .changeWrap {
    display: inline-flex;
    align-items: baseline;
    padding-left: 10px;
    font-size: 17px;
    line-height: 23px;
  }
}
