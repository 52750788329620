$max-width: 260px;

.dropdownWrap {
  position: relative;
  display: inline-block;
  text-decoration: none;

  .widget {
    display: inline-flex;
    white-space: nowrap;
    position: relative;
    cursor: default;

    // a fake underline because we need it to stretch to full width
    &::before {
      content: " ";
      position: absolute;
      border-bottom: 1px dotted currentColor;
      width: 100%;
      height: 1.1867em;
    }

    .value {
      display: inline-flex;
      flex-direction: column;
      align-items: stretch;

      .currentLabel {
        text-overflow: ellipsis;
        display: inline-block;
        max-width: $max-width;
        overflow: hidden;
        text-align: left;
      }

      .spacer {
        max-width: $max-width;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }

    svg {
      padding-left: 3px;
      opacity: 0.5;
      align-self: center;
    }
  }

  select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    appearance: none;
    opacity: 0;
  }

  &.disabled {
    .widget {
      cursor: not-allowed;
    }

    select {
      pointer-events: none;
    }

    .value {
      opacity: 0.5;
    }
  }
}

.dropdownWrap:focus-within .widget::before,
.dropdownWrap:hover .widget::before {
  border-bottom: 1px solid currentColor;
}
