.video {
  div:first-child {
    height: 100%;

    .iframe {
      width: 100%;
      height: 100%;
    }

    .spinner {
      left: 50%;
      margin-left: -2.5em;
      position: absolute;
      top: 50%;
    }
  }
}
