/* stylelint-disable color-no-hex */
/* stylelint-disable grid/no-color-if-var  */

// - vars -

$layer-slider-label: 4;
$layer-slider: 5;
$layer-slider-thumb: 6;
$designs-mobile: 498px;
$stripe-size: 3px;
$stripe-gap: 9px;
$inputElementHeight: 27px;
$step-x: 1.5rem;
$doc-width: 768px;
$wide-width: 1160px;
$designs-tablet: 770px;
$layout-margin-space: 30px;

$print-black: #000;
$print-white: #fff;
$print-lightgray: #b2b2b2;
$print-faintgray: #eee;
$print-link-color: #0025ff; // $aster
$grid-purple: #5900ff;

// input widths
$XSMALL: 64px;
$SMALL: 99px;
$MEDIUM: 140px;
$LARGE: 229px;
$XLARGE: 358px;

// layout elements
$column-padding-tb: 7px;
$column-padding-lr: 16px;
$gutter: 16px;

// - mixins -

@mixin inputWrapperStyles() {
  align-items: center;
  background: var(--input-background);
  border-radius: 4px;
  border: 1px solid var(--input-border);
  box-sizing: border-box;
  height: $inputElementHeight;
  max-width: 100%;
  min-width: 0;
  position: relative;
  width: 100%;
  display: inline-flex;
  vertical-align: middle;
  margin-top: -2px;

  &:hover:not(.disabled) {
    border-color: var(--input-border-hover);
  }

  &:focus-within {
    border-color: var(--accent-color);
  }

  &.disabled {
    border-color: var(--disabled-border);
    background-color: var(--disabled-background);
    color: var(--disabled-text);
  }
}

@mixin inputStyles() {
  appearance: none;
  background: none;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  color: var(--input-text);
  caret-color: var(--input-text);
  font-family: var(--controls-font);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  height: 25px;
  line-height: 25px;
  margin: 0;
  overflow: hidden;
  max-width: 100%;
  padding: 0 12px;
  vertical-align: middle;
  white-space: nowrap !important;
  width: 100%;

  &:focus {
    border: none;
    outline: none;
  }

  &:disabled {
    color: var(--disabled-text);
    cursor: not-allowed !important;
    opacity: 1;
  }

  &::placeholder {
    color: var(--input-placeholder);
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    margin-right: -6px;
    background-color: currentColor;
    opacity: 0.6;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path fill="none" stroke="white" stroke-width="2" d="M1,1 L9,9 M1,9 L9,1"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px 8px;
    border-radius: 1em;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 0.8;
    }
  }
}
