.mention {
  margin: 0 1px;
  cursor: pointer;
  position: relative;

  .loading {
    position: relative;

    .spacer {
      visibility: hidden;
    }

    .indicator {
      position: absolute;
      left: 0;
    }
  }

  &.document {
    background-color: var(--color-90);
    color: inherit;
    border-radius: 4px;
    text-decoration: none;
    padding: 0 4px;
    font-size: .7895em;
    line-height: 27px;
    display: inline-block;
    font-family: var(--controls-font);
    font-weight: 500;
    transition: background-color 240ms, color 240ms ease;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
      padding-right: 4px;
    }

    &:hover {
      background-color: var(--color-60);
      color: var(--background-color);
      text-decoration: none;
      transition: background-color 240ms, color 240ms ease;
    }
  }

  &.selected {
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: -2px;
      left: -2px;
      border: 2px solid var(--editor-focus-color);
      border-radius: 4px;
    }
  }
}
