.root {
  display: block;
  position: absolute;
  border: 1px solid var(--dp-border);
  border-radius: 4px;
  overflow: hidden;
  background: var(--dp-background);
  font-family: var(--controls-font);
  color: var(--dp-text);
  box-shadow: rgba(0, 0, 0, 10%) 0 2px 8px 2px; /* stylelint-disable-line grid/no-color-if-var */
  padding: 2px;
  width: min-content;
  cursor: default;
  z-index: 10000;
  top: -1000px;
  left: -1000px;

  table {
    border-collapse: collapse;
    table-layout: fixed;
  }
}
