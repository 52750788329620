$boxRounding: 4px;

.checkmark {
  outline: none;
  display: inline-block;
  vertical-align: middle;
  margin: -4px 0 -2px;
  line-height: 1px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
  color-adjust: exact;
  -webkit-print-color-adjust: exact;

  :global(.selection) & {
    opacity: .7;

    svg {
      opacity: 1;
    }
  }

  span {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &:focus .box,
  &:focus .disc {
    box-shadow: 0 0 0 1.5px var(--focus-color);
  }

  svg, .fill {
    position: absolute;
    top: 0;
    left: 0;
  }

  .stroke {
    display: none;
  }

  .fill {
    border-radius: $boxRounding;
    border: 1px solid var(--input-border);
    transition: background-color 200ms, border-color 200ms;
    background-color: var(--input-background);
  }

  .box {
    border-radius: $boxRounding;
  }

  .disc {
    border-radius: 50%;

    .fill {
      border-radius: 50%;
    }
  }

  &:hover .fill {
    background-color: var(--input-background-hover);
    border-color: var(--input-border-hover);
  }

  .checked {
    .fill {
      background-color: var(--checked-background);
      border-color: var(--checked-border);
    }

    path {
      stroke: var(--checked-text);
    }

    circle {
      fill: var(--checked-text);
    }
  }

  &:hover .checked {
    .fill {
      background-color: var(--checked-background-hover);
    }
  }

  .disabled {
    cursor: not-allowed;
  }

  .disabled .fill {
    background-color: var(--disabled-background);
    border: 1px solid var(--disabled-border);
  }

  .disabled.checked {
    path {
      stroke: var(--disabled-text);
    }

    circle {
      fill: var(--disabled-text);
    }
  }
}
