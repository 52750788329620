@import "../gridstyles.module";

.image {
  vertical-align: middle;
  line-height: 1;

  * {
    line-height: 0;
  }

  .linkIndicator {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  &.roundedRect .linkIndicator,
  &.circle .linkIndicator,
  &.ellipse .linkIndicator {
    right: calc(50% - 5px);
  }

  &.roundedRect.withBorder .linkIndicator,
  &.circle.withBorder .linkIndicator,
  &.ellipse.withBorder .linkIndicator {
    right: calc(50% - 9px);
  }

  &.clickable {
    cursor: pointer;
  }

  .imgWrap {
    display: inline-block;
  }

  img {
    max-width: 100%;
  }
}

.fullWidth {
  width: 100%;

  .imgWrap, img {
    width: 100%;
  }
}

.withBorder img {
  max-width: calc(100% - 4px);
}

.withBorder.circle .imgWrap {
  clip-path: circle();
  padding: 2px;
}

.roundedRect img {
  border-radius: 12px;
}

.circle img {
  background: var(--background-color);
  clip-path: circle();
  max-height: $doc-width;
  max-width: 100%;
  object-fit: cover;
}

.withBorder.ellipse .imgWrap {
  clip-path: ellipse();
  padding: 2px;
}

.ellipse img {
  background: var(--background-color);
  clip-path: ellipse();
  max-width: 100%;
}

// editor needs some space to fit caret
.editor.image {
  max-width: calc(100% - 2px);
}

// this is the editor border element, not the image border
.image .border {
  vertical-align: inherit;
  display: inherit;
  width: inherit;
}
