@import "./gridstyles.module";

.grid {
  display: inline-block;
  position: relative;
  caret-color: var(--text-color);

  a:hover &.text {
    text-decoration: underline;
    cursor: pointer;
  }

  &.block {
    display: block;
  }

  &:global(.chart) svg {
    display: block;
  }

  &.invisible {
    background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent $stripe-gap,
      hsla(var(--text-color-hsl), 0.1) $stripe-gap,
      hsla(var(--text-color-hsl), 0.1) ($stripe-gap + $stripe-size)
    );

    > :first-child {
      opacity: .5;
    }
  }

  .baseFont {
    font-size: inherit;
  }

  &.empty {
    &::before {
      content: "  ";
    }

    vertical-align: bottom;
  }
}

.clicktrap > * {
  pointer-events: none;
}

.title {
  margin-bottom: 1px !important;
}

.editor {
  &.block {
    user-select: none;
  }

  // Element is a part of a selection range.
  &:global(.selection) {
    user-select: none;
    background: var(--selection-color);

    &.useAfter::after {
      top: 0;
      left: 0;
    }

    canvas, img, button, svg {
      opacity: .7;
    }

    ::selection {
      background: transparent;
    }
  }

  // disable text selection here
  .label::selection,
  .innerlabel::selection,
  &.text::selection {
    background: transparent;
  }
}

.grid svg {
  a {
    fill: var(--link-color);
  }

  a:hover {
    fill: var(--link-hover-color);
  }
}
