/* stylelint-disable selector-pseudo-class-no-unknown, property-no-unknown, property-case */

$speed: 200ms;

.input {
  border: 0;
  line-height: inherit;
  display: none;
  outline: none;
  color: var(--input-text);
  caret-color: var(--input-text);
  font-family: var(--controls-font);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  background: var(--input-background);
  border-radius: 2px;
  margin: 0;
  border-bottom: 1px solid var(--input-border);
  margin-bottom: -1px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 4px;
  vertical-align: top;
  white-space: nowrap !important;
}

.value {
  text-decoration: underline currentColor dotted 1px;
  text-underline-offset: 2px;
  opacity: 1;
}

.boxed {
  .input {
    position: absolute;
    left: 0.4em;
    right: 0.4em;
    display: unset;
    width: unset;
    border: 1px solid var(--input-border);
    border-radius: 4px;
    height: 1.6em;
    top: 50%;
    transform: translate(0, -50%);
  }

  .value {
    display: none;
  }

  @media print {
    display: block;
    position: static;
    width: 100%;
  }
}

.inputLabel {
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: block;
  margin: 0;
}

.inputLabel:hover {
  .value {
    text-decoration: underline currentColor solid 1px;
  }
}

.interacting {
  .input {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: unset;
    width: 100%;
    animation-name: slidein;
    animation-duration: $speed;
    animation-iteration-count: initial;
  }

  .value {
    animation-name: fadeout;
    animation-duration: $speed;
    animation-iteration-count: initial;
    opacity: 0;
  }
}

.exiting {
  .input {
    display: unset;
    width: 0%;
    animation-name: slideout;
    animation-duration: $speed;
    animation-iteration-count: initial;
    opacity: 0;
  }

  .value {
    animation-name: fadein;
    animation-duration: $speed;
    animation-iteration-count: initial;
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    width: 0%;
    padding: 0;
    opacity: 0;
  }

  to {
    width: 100%;
    opacity: 1;
  }
}

@keyframes slideout {
  from {
    width: 100%;
    opacity: 1;
  }

  to {
    width: 0%;
    opacity: 0;
    padding: 0;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

:export {
  transitionSpeed: $speed;
}
