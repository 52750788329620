.helper {
  cursor: default;
  padding-left: 1px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;

  svg {
    margin-top: -2px;
    width: 26px;
    height: 26px;
    display: inline-block;
    opacity: .6;
  }
}
