/* stylelint-disable max-line-length */
@import "../gridstyles.module";

.ruler {
  box-sizing: border-box;
  padding-top: 12px;

  &.invisible {
    background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent $stripe-gap,
      hsla(var(--text-color-hsl), 0.1) $stripe-gap,
      hsla(var(--text-color-hsl), 0.1) ($stripe-gap + $stripe-size)
    );
  }

  div {
    background: var(--ruler-color);
    height: 1px;
    margin: 0 auto;
    mask-repeat: space, repeat-x;
    max-width: 100%;
    transition: width 240ms ease, height 240ms ease;
    width: 100%;
  }

  &.left div {
    margin-left: 0;
  }

  &.right div {
    margin-right: 0;
  }

  /***** Solid line ***************************************************************************************************/
  &.solid {
    &.heavy {
      padding-top: 10px;

      div {
        border-radius: 2px;
        height: 4px;
      }
    }

    &.partial div {
      width: 199px;
    }
  }

  /***** Dashed line **************************************************************************************************/
  &.dashed {
    div {
      mask-image: url(
        "data:image/svg+xml," +
        '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="1">' +
        '<line x1="5.5" x2="14.5" y1=".5" y2=".5" stroke="black" stroke-linecap="round"/>' +
        "</svg>"
      );
    }

    &.heavy {
      padding-top: 11px;

      div {
        height: 3px;
        mask-image: url(
          "data:image/svg+xml," +
          '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="3">' +
          '<line x1="5.5" x2="14.5" y1="1.5" y2="1.5" stroke="black" stroke-linecap="round" stroke-width="3"/>' +
          "</svg>"
        );
      }
    }

    &.partial div {
      width: 199px;
    }
  }

  /***** Circles ******************************************************************************************************/
  &.circles {
    padding-top: 10px;

    div {
      height: 4px;
      mask-image: url(
        "data:image/svg+xml," +
        '<svg width="12" height="4" xmlns="http://www.w3.org/2000/svg">' +
        '<circle cx="6" cy="2" r="2" fill="black"/>' +
        "</svg>"
      );
    }

    &.partial div {
      width: 196px;
    }

    &.heavy {
      padding-top: 8px;

      div {
        height: 8px;
        mask-image: url(
          "data:image/svg+xml," +
          '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="8">' +
          '<circle cx="9" cy="4" r="4" fill="black"/>' +
          "</svg>"
        );
      }

      &.partial div {
        mask-image: url(
          "data:image/svg+xml," +
          '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="8">' +
          '<circle cx="4" cy="4" r="4" fill="black"/>' +
          "</svg>"
        );
        mask-repeat: repeat-x;
        width: 104px;
      }
    }
  }

  /***** Squiggles ****************************************************************************************************/
  &.squiggle {
    padding-top: 10px;

    div {
      height: 5px;
      mask-image: url(
        "data:image/svg+xml," +
        '<svg xmlns="http://www.w3.org/2000/svg" width="13" height="6" fill="none">' +
        '<path fill="black" fill-rule="evenodd" d="M0 1V0C0.895729 0 1.79146 0.341736 2.47487 1.02515L4.73223 3.28247C5.70854 4.25879 7.29146 4.25879 8.26777 3.28247L10.5251 1.02515C11.2085 0.341675 12.1043 0 13 0V1C12.3602 1 11.7204 1.24408 11.2322 1.73224L8.97487 3.98956C7.60804 5.35645 5.39196 5.35638 4.02512 3.98956L1.76777 1.73224C1.27961 1.24408 0.639807 1 0 1Z" clip-rule="evenodd"/>' +
        "</svg>"
      );
      mask-repeat: repeat-x;
    }

    &.heavy {
      padding-top: 9px;

      div {
        height: 7px;
        mask-image: url(
          "data:image/svg+xml," +
          '<svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" fill="none">' +
          '<path fill="black" fill-rule="evenodd" d="M0 3.00006V0C1.15165 0 2.3033 0.439331 3.18198 1.318L5.43934 3.575C6.025 4.16 6.97 4.16 7.56 3.57L9.82 1.318C10.697 0.439 11.85 0 13 0V3C12.6161 3 12.2322 3.14648 11.9393 3.439L9.68 5.69C7.92 7.45 5.07538 7.4541 3.31802 5.69672L1.06 3.439C0.767 3.146 0.3838 3 0 3Z" clip-rule="evenodd"/>' +
          "</svg>"
        );
      }
    }

    &.partial div {
      width: 192px;
    }
  }
}
