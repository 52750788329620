.debugline {
  .handle {
    height: 0;
    width: 0;
    display: inline-block;
    position: absolute;
    border-bottom: 10px solid currentColor;
    opacity: .5;
    margin-left: -6px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  .line {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: calc(100vw - 16px);
    border-bottom: 1px dashed currentColor;
    z-index: 10000;
  }
}
