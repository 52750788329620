@import "styles";

$title-fs: 14px;
$label-fs: 12px;
$value-fs: 12px;

.simpleHoverInfo {
  display: grid;
  font-size: $label-fs;
  grid-template-columns: 3fr 1fr;
  column-gap: 8px;
  padding: 4px 0;
  width: max-content;
  white-space: break-spaces;
  max-width: 100%;

  .title {
    grid-column-start: 1;
    font-size: $label-fs;
    font-weight: bold;

    &.titleSolo {
      padding-right: 10px;
      font-weight: normal;
    }
  }

  .subtitle {
    grid-row-start: 2;
  }

  .val {
    font-size: $value-fs;
    font-weight: bold;
    grid-column-start: 2;
    grid-row-start: 1;
    justify-self: end;
  }

  &.valOnly {
    display: flex;

    .val {
      align-self: center;
    }
  }

  .children {
    border-top: 1px solid rgba($white, .2);
    grid-column-start: span 2;
    margin-top: 8px;
    padding-top: 8px;
  }
}

.hoverInfo {
  .titles {
    display: grid;
    grid-template-columns: 12px auto;

    &.titlesSpaced {
      margin-bottom: 12px;
    }
  }

  .title {
    font-weight: bold;
    font-size: $title-fs;
    grid-column-start: span 3;
    grid-row-start: 1;
  }

  .subtitle {
    font-size: $label-fs;
    grid-row-start: 2;
    grid-column-start: span 3;
  }

  .swatch {
    align-self: center;
    border-radius: 4px;
    grid-column-start: 1;
    grid-row-start: 1;
    height: 8px;
    margin-right: 4px;
    width: 8px;
    vertical-align: middle;
  }

  .subtitleSwatch {
    grid-row-start: 2;
  }

  .dims {
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 8px;
    row-gap: 5px;
    line-height: 1.15;
    font-size: $label-fs;

    .key::after {
      content: ":";
    }

    .val {
      font-size: $value-fs;
      font-weight: bold;
      justify-self: end;
    }
  }

  .children {
    border-top: 1px solid rgba($white, .2);
    margin-top: 8px;
    padding-top: 8px;
  }
}
