@import "../gridstyles.module";

.invisibleTextBlock {
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent $stripe-gap,
    hsla(var(--text-color-hsl), 0.1) $stripe-gap,
    hsla(var(--text-color-hsl), 0.1) ($stripe-gap + $stripe-size)
  );
  color: hsla(var(--text-color-hsl), 0.5);

  .invisible:not(.timer) {
    // flip the direction of stripes when nested so this is visible
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent $stripe-gap,
      hsla(var(--text-color-hsl), 0.1) $stripe-gap,
      hsla(var(--text-color-hsl), 0.1) ($stripe-gap + $stripe-size)
    ) !important;
  }

  // need opaque text color on text because all grid elements will
  // be transparent and text should not be double-transparent
  .text {
    color: var(--text-color);
  }

  // inline elements are half-transparent
  a,
  .grid .border > * {
    opacity: .5;
  }
}
