.hasHelper {
  position: relative;

  > input,
  > select {
    padding-right: var(--helper-width, 25px) !important;
    // left in for debugging purposes:
    // background: linear-gradient(
    //   90deg,
    //   rgba(255, 255, 255, 0%) 0,
    //   rgba(255, 255, 255, 1%) calc(100% - var(--helper-width, 25px)),
    //   rgba(255, 196, 196, 50%) calc(100% - var(--helper-width, 25px)),
    //   rgba(255, 196, 196, 50%) 100%
    // );
  }

  input::-webkit-calendar-picker-indicator {
    appearance: none !important;
    display: none !important;
    opacity: 0;
  }
}

.helper {
  outline: none;
  display: flex;
  position: absolute;
  top: calc(50% - (var(--helper-height) / 2));
  right: 0;
  width: var(--helper-width);
  height: var(--helper-height);
  line-height: var(--helper-height);
  box-sizing: border-box;
  padding: 0 4px;
  font-size: 19px;
  align-items: center;
}

.calendar,
.arrow {
  background: var(--helper-background);
  display: none;
  vertical-align: middle;
  text-align: center;
  width: var(--helper-inner-width);
  height: var(--helper-inner-height);
  line-height: var(--helper-inner-height);
  border-radius: 4px;
}

.calendar {
  display: inline;
  border: 0;
  padding: 0;
  font-size: 12px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.dateHelper button {
  background: none;
}

.selectHelper {
  pointer-events: none;

  .arrow {
    display: inline-flex;
    fill: var(--helper-text);
    align-items: center;
    justify-content: center;

    svg {
      display: inline-block;
    }
  }

  .disabled {
    background: none;

    path {
      fill: var(--disabled-text);
    }
  }
}

.hasHelper:hover .selectHelper {
  .arrow:not(.disabled) {
    background: var(--helper-background-hover);

    path {
      fill: var(--helper-text-hover);
    }
  }
}

.numberHelper {
  button {
    appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    cursor: pointer;
    position: absolute;
    right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--helper-background);
    transition-property: width, height, top, right, bottom, border-radius;
    transition-duration: .15s;
    width: var(--helper-inner-width);
    height: calc(var(--stepper-height) / 2);
    fill: var(--helper-text);

    &:disabled {
      cursor: not-allowed;
      background: none;

      path {
        fill: var(--disabled-text);
      }
    }

    &:focus {
      outline: none;
    }

    &:hover:not(:disabled) {
      background: var(--helper-background-hover);

      path {
        fill: var(--helper-text-hover);
      }
    }

    &.up {
      border-radius: 2px 2px 0 0;
      top: calc(50% - var(--stepper-height) / 2 - .5px);
    }

    &.down {
      border-radius: 0 0 2px 2px;
      top: calc(50% + .5px);
    }
  }

  button:active,
  &:not(.mobileHelper) button:hover:not(:disabled) {
    background: var(--helper-background-hover);
    border-color: var(--helper-background-hover) !important;

    path {
      fill: var(--helper-text-hover);
    }
  }
}

input:focus + .mobileHelper.numberHelper button {
  border: 1px solid var(--helper-border);
  border-radius: 2px;
  height: 23px;
  width: 23px;
  z-index: 1;
  // stylelint-disable-next-line
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  span {
    height: 34px;
    width: 34px;
    position: absolute;
    left: 0;
    // stylelint-disable-next-line
    background: rgba(0, 0, 0, 0.001);
  }

  &.up span {
    bottom: 0;
  }

  &.down span {
    top: 0;
  }

  &.up {
    top: -12px;
    top: calc(50% - 23px - 1px);
    right: 2px;
  }

  &.down {
    bottom: -12px;
    top: calc(50% + 1px);
    right: 2px;
  }
}

.booleanHelper {
  button {
    --size: calc(min(var(--helper-inner-width), var(--helper-inner-width)));

    appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    cursor: pointer;
    background: var(--helper-background);
    vertical-align: middle;
    text-align: center;
    width: var(--size);
    height: var(--size);
    line-height: var(--size);
    border-radius: 4px;

    svg {
      width: 100%;
      height: 100%;
    }

    &.checked {
      background: var(--helper-active-background);

      path {
        stroke: var(--helper-active-text);
      }

      &:hover:not(:disabled) {
        background: var(--helper-active-background-hover);

        path {
          stroke: var(--helper-active-text-hover);
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      background: none;

      path {
        stroke: var(--disabled-text);
      }
    }

    &:hover:not(:disabled) {
      background: var(--helper-background-hover);

      path {
        stroke: var(--helper-text-hover);
      }
    }
  }
}
