.slider {
  transform: translate(0, -2px);

  &[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    background: transparent;
    outline: none;
    display: inline-block;
    height: 16px;
    margin: 0;
    cursor: pointer;
    vertical-align: top;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }

  &[type="range"]::-webkit-slider-runnable-track {
    border-radius: 8px;
    height: 4px;
    background-color: var(--slider-track);
  }

  &[type="range"]::-moz-range-track {
    border-radius: 8px;
    height: 4px;
    background-color: var(--slider-track);
  }

  &[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    background-color: var(--slider-thumb);
    border: 2px solid var(--slider-thumb-mask);
    margin-top: -6.5px;
    width: 16px;
    height: 16px;
    border-radius: 10px;
  }

  &[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    background-color: var(--slider-thumb);
    border: 2px solid var(--slider-thumb-mask);
    width: 16px;
    height: 16px;
    border-radius: 10px;
  }

  &[type="range"]:hover::-moz-range-track {
    background-color: var(--slider-track-hover);
  }

  &[type="range"]:hover::-webkit-slider-runnable-track {
    background-color: var(--slider-track-hover);
  }

  &[type="range"]:active::-moz-range-track {
    background-color: var(--slider-track-active);
  }

  &[type="range"]:active::-webkit-slider-runnable-track {
    background-color: var(--slider-track-active);
  }

  &[type="range"]:focus::-webkit-slider-thumb {
    border: 1.5px solid var(--focus-color);
    box-shadow: 0 0 0 0.5px var(--slider-thumb-mask);
  }

  &[type="range"]:focus::-moz-range-thumb {
    border: 1.5px solid var(--focus-color);
    box-shadow: 0 0 0 0.5px var(--slider-thumb-mask);
  }
}

.sliderWrap {
  box-sizing: border-box;
  min-width: 140px;
  overflow-y: clip;
  height: 1em;
  min-height: 24px;
  vertical-align: middle;
  margin: 0 auto;
  width: calc(100% - 1.4em);
  display: inline-block;
  position: relative;
}
