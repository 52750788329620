.tooltip {
  padding: 4px 8px;
  background: var(--tooltip-background);
  border-radius: 4px;
  color: var(--tooltip-color);
  font-family: var(--tooltip-font);
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 125%;
  max-width: 200px !important;
  position: absolute !important;
  white-space: pre-line;
  display: block;
  z-index: 1001; // $layer-tooltip
  text-align: left;
  width: max-content !important;
  margin: 0 !important;
  top: 0;
  left: 0;

  .anchor {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 4px solid var(--tooltip-background);
    transform: rotate(270deg);
    margin-top: -4px;
    margin-left: -1px;
    left: 50%;
    top: 100%;

    &.hide {
      display: none;
    }
  }

  a {
    color: var(--tooltip-link-color);

    &:hover {
      opacity: 0.8;
      color: var(--tooltip-link-color);
    }
  }
}
