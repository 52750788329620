table td .button {
  height: 30px;
  width: 30px;
  border-radius: 4px;
  padding: 0;
  display: block;
  cursor: pointer;
  position: relative;
  line-height: 30px;

  &.disabled {
    color: var(--dp-disabled-text);
    cursor: default;
  }

  &.today::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    border: 1px solid currentColor;
    padding: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }

  &:not(.disabled):hover {
    background: var(--dp-hover);
  }

  &.selected {
    background: var(--dp-selected) !important;
  }

  &.selected:hover {
    text-decoration: underline;
  }

  &:not(.disabled).overflow {
    color: var(--dp-overflow-date);
  }
}
