.text {
  font-size: unset;
}

.text:global(.col_mode) {
  line-height: unset;
}

.outlined .emptyText {
  padding: 0 8px;
}

.value {
  width: 100%;
  display: inline-table;
}

.inline.value {
  width: auto;
  display: inline;
}

// text element has a dotted underline
.outlined .value {
  text-decoration: underline var(--color-60) dashed 1px;
  text-underline-offset: 2px;
  cursor: default;
}
