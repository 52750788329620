.document {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  flex-basis: 674px;

  // XXX: it would be better if this were located in document.scss
  &.useFullWidth {
    > * {
      max-width: none !important;
    }
  }
}
