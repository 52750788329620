@import "../../gridstyles.module";

$track-height: 4px;

.sliderUI {
  display: inline-block;
  vertical-align: middle;
  padding: 11px 4px 7px 0;
  width: 100%;
  height: 6px;
  cursor: pointer;
  position: relative;
  -webkit-touch-callout: none;
  touch-callout: none; /* stylelint-disable-line */
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  touch-action: none;

  --thumb-size: 20px;

  &.labelspace {
    padding-bottom: 26px;
  }

  .track {
    display: block;
  }

  .trackFill {
    display: block;
    height: $track-height;
    width: 100%;
    background: var(--slider-track);
    border-radius: 3px;
    margin-bottom: -$track-height;
  }

  .trackOverlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% - 4px);
    z-index: $layer-slider;
  }

  .thumbWrap {
    position: absolute;
    left: calc((var(--thumb-size) / 2) - 3px);
    right: calc((var(--thumb-size) / 2) + 1px);
    top: calc(13px - (var(--thumb-size) / 2));
    display: block;
    box-sizing: border-box;
    height: 8px;
  }

  body:global(.slider_active) & .thumb,
  &.active .thumb {
    transition-property: none !important;
  }

  .thumb {
    outline: none;
    display: block;
    position: absolute;
    box-sizing: border-box;
    margin-left: -10px;
    top: 0;
    width: var(--thumb-size);
    height: var(--thumb-size);
    cursor: pointer;
    border-radius: 50%;
    z-index: $layer-slider-thumb;
    transition-property: left;
    transition-duration: 150ms;
    transition-timing-function: ease;

    .thumbStroke {
      background: var(--slider-thumb-mask);
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;

      :global(.selection) & {
        background: var(--selection-color);
      }
    }

    .thumbFill {
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      background: var(--slider-thumb);
      width: calc(var(--thumb-size) - 8px);
      height: calc(var(--thumb-size) - 8px);
      border-radius: 50%;
      border: 1px solid var(--slider-border);
    }

    @media print {
      top: -11px;
    }
  }

  .thumb:focus {
    .thumbFill {
      box-shadow: 0 0 0 1.5px var(--focus-color);
    }
  }

  .label {
    display: block;
    width: 100%;
    position: absolute;
    top: 24px;
    line-height: 16px !important;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    padding: 0;
    z-index: $layer-slider-label;
  }

  &.labelspace .label {
    background: none;
  }

  &.disabled .label {
    color: var(--disabled-text);
  }

  .ticks {
    display: block;
    position: relative;
    margin-left: (14px / 2);
    margin-right: (14px / 2) - 0.5px;
    height: $track-height;

    .tick {
      display: inline-block;
      position: absolute;
      top: ($track-height / 2) - 1px;
      margin-left: -1px;
      width: 2px;
      height: 2px;
      border-radius: 1px;
      background: var(--slider-tick);
    }

    .tickZero {
      background: var(--slider-tick0);
    }
  }

  &:hover:not(.disabled) {
    .trackFill {
      background: var(--slider-track-hover);
    }
  }

  &.active:not(.disabled) {
    .trackFill {
      background: var(--slider-track-active);
    }
  }

  &.disabled {
    .track,
    .trackFill,
    .thumb {
      cursor: not-allowed;
    }

    .trackFill {
      background: var(--color-90);
    }

    .thumbFill {
      background: var(--disabled-text);
    }
  }
}
