.tangle {
  border-radius: 0;
  cursor: ew-resize;
  display: inline-block;
  min-width: 1rem;
  text-align: center;
  text-decoration: underline currentColor dotted 1px;
  text-underline-offset: 2px;
  touch-action: none;

  &.invalid {
    cursor: unset;
    text-decoration: none;
  }
}
