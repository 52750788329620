.calendar {
  thead th {
    font-size: 11px;
    color: var(--dp-weekdays);
  }

  td {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
}
