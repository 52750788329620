@import "../gridstyles.module";

.slider {
  max-width: 100%;
  padding: 0 1px;
  box-sizing: border-box;

  .inputWrap {
    display: inline-flex;
    vertical-align: top;
    width: 100%;
  }

  &:global(.size_medium) {
    line-height: 32.5px;

    .inputWrap {
      padding-top: 5px;
      margin-top: -2px;
    }
  }

  &:global(.size_large) {
    line-height: 43px;

    .inputWrap {
      padding-top: 10px;
      margin-top: -2px;
    }
  }
}
