@import "../gridstyles.module";

$shadow: 0 4px 4px rgba($print-black, 0.04), 0 10px 15px rgba($print-black, 0.1);

.row {
  box-sizing: border-box;
  display: block;
  min-height: 20px;

  &.shadow {
    box-shadow: $shadow;
  }

  &:first-child {
    margin-top: 0;
  }

  .rowInner {
    width: calc(100% + 16px);
    margin-left: -16px;
  }

  &.hasBorder {
    border: 1px solid currentColor;
  }

  &.invisible {
    background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent
      9px,
      var(--color-90) 9px,
      var(--color-90) 12px
    );

    // use a dashed "gray" border to try to indicate that this column
    // is hidden as well as its parent (which is likely unintended)
    .col.invisible:not(.filled) {
      border-color: var(--color-90);
      border-style: dashed;
      border-width: 3px;
      padding: (
        ($column-padding-tb - 3)
        ($column-padding-lr - 3)
      );
      background: none;
    }

    // use a solid color border to try to indicate that this column is not
    // hidden even though its parent is
    .col.filled:not(.invisible) {
      border-color: var(--background-color);
      border-style: solid;
      border-width: $column-padding-tb $column-padding-lr;
      padding: 0;
      background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent
        9px,
        var(--color-90) 9px,
        var(--color-90) 12px
      );
    }
  }
}

.col {
  width: calc(100% - #{$gutter});
  display: block;
  box-sizing: border-box;
  border: 0;
  transition: opacity 150ms;
  margin-left: $gutter;
  padding: $column-padding-tb $column-padding-lr;
  border-radius: var(--inner-radius, 20px);

  &.hasBorder,
  .hasBorder & {
    padding: calc($column-padding-tb - var(--border-width, 1px)) calc($column-padding-lr - var(--border-width, 1px));
  }

  .hasBorder &.hasBorder {
    padding: (
      calc($column-padding-tb - var(--border-width, 1px) * 2)
      calc($column-padding-lr - var(--border-width, 1px) * 2)
    );
  }

  // if there isn't a set background we need to re-apply the default
  // background so that shadows of elements top of this layout don't
  // bleed through.
  :not(.hasBackground) & {
    background: var(--background-color);

    .invisible & {
      background: repeating-linear-gradient(
        -45deg,
        var(--background-color),
        var(--background-color)
        9px,
        var(--color-90) 9px,
        var(--color-90) 12px
      );
    }
  }

  &.shadow {
    box-shadow: $shadow;
  }

  &.alignMiddle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.alignBottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  &.hidden {
    display: none;
  }

  &.invisible {
    background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent
      9px,
      var(--color-90) 9px,
      var(--color-90) 12px
    );
  }

  .colInner {
    width: 100%;
    box-sizing: border-box;
  }

  &.colBeingDragged {
    opacity: 0.25;
  }
}

.col + .col {
  margin-top: 15px;
  margin-left: $gutter;
}

// Firefox has rounding errors under some conditions (when an iframe width is not
// a whole pixel). This causes layouts to break. This browser hack is to counter
// the error by giving FF a leverage of a fraction of a pixel per gutter. #GRID-4284
/* stylelint-disable */
@-moz-document url-prefix() {
  .col + .col {
    margin-left: $gutter - 0.05px;
  }
}
/* stylelint-enable */

@media (min-width: $designs-mobile), print {
  .row {
    display: block;

    &-middle {
      align-items: center;
    }

    // removes padding from first and last columns in full width
    // if they don't have backgound color set
    &-full:not(.filled) {
      .col:not([data-slate-node]):not(.filled) {
        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    .rowInner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
    }
  }

  .col {
    width: calc(100% - #{$gutter});
    flex-grow: 0;
    flex-shrink: 0;

    &-1\/1 {
      width: calc(100% - #{$gutter});
    }

    &-1\/2,
    &-2\/4 {
      width: calc(50% - #{$gutter});
    }

    &-1\/4 {
      width: calc(25% - #{$gutter});
    }

    &-3\/4 {
      width: calc(75% - #{$gutter});
    }

    &-1\/3 {
      width: calc(33.33% - #{$gutter});
    }

    &-2\/3 {
      width: calc(66.66% - #{$gutter});
    }

    &-1\/5 {
      width: calc(20% - #{$gutter});
    }

    &-1\/6 {
      width: calc(16.664% - #{$gutter});
    }

    &-outlined {
      border: none !important;
    }

    &.hidden {
      display: block;
    }
  }

  .col + .col {
    margin-top: 0;
  }
}

.layoutControl {
  display: none;
  transition: opacity 0.25s;
}
