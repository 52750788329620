@import "../../gridstyles.module";

.button {
  display: inline-block !important;

  :global(.value) {
    display: inline-flex !important;
  }

  .buttonElm {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--accent-color);
    border-radius: 14px;
    border: 1px solid var(--button-border);
    box-sizing: border-box;
    color: var(--button-text);
    cursor: pointer;
    display: inline-block;
    font-family: var(--controls-font);
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    margin: 0;
    outline: none;
    padding: 0 15px;
    text-decoration: none !important;
    transition: background-color .15s;
    vertical-align: middle;
    margin-top: -2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: var(--accent-color-hover);
    }

    &:focus {
      outline: none;
      transition: box-shadow 0.2s;
      box-shadow: 0 0 0 1.5px var(--focus-color);
    }

    &:active {
      outline: none;
      box-shadow: none;

      .label {
        opacity: .8;
      }
    }

    &[disabled],
    &.disabled {
      opacity: 1;
      cursor: not-allowed;
      border: 1px solid var(--disabled-border);
      background-color: var(--disabled-background);
      color: var(--disabled-text);

      .label {
        border: none;
      }
    }

    .disabled .stroke {
      border-color: currentColor;
      opacity: .3;
    }

    &::-moz-focus-inner {
      border: 0;
    }
  }
}
