.sheet {
  max-width: 100%;
  overflow-y: visible;
}

.tabs {
  margin-top: 4px;
  padding: 0;
  display: flex;
  background: var(--color-95);
  border: 2px solid var(--color-95);
  border-radius: 14px;
  width: fit-content;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tab {
  border: 1px solid transparent;
  background: transparent;
  padding: 2px 12px;
  margin: 0;
  border-radius: 14px;
  font-size: 14px;
  line-height: 14px;
  font-family: var(--controls-font);
  cursor: pointer;
}

.tab.current {
  border: 1px solid var(--color-80);
  background: var(--background-color);
}
