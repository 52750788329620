.label,
.innerlabel {
  font-size: var(--label-font-size);
  font-family: var(--body-font);
  display: inline-block;
  vertical-align: baseline;

  &.disabled {
    color: var(--disabled-text);
  }
}

.innerlabel {
  display: inline;
  margin-right: 8px;
}

.label {
  opacity: inherit;
  line-height: 25px;
  display: block;
}
