.source {
  position: relative;

  a {
    box-sizing: border-box;
    line-height: 19px;
    border-radius: 6px;
    display: inline-block;
    text-decoration: none;
    margin: 0;
    position: relative;
    cursor: pointer;
    font-family: var(--controls-font);
    font-size: 0.74em;
    font-weight: 500;
    color: inherit;
    padding: 4px 4px 3px 23px;

    &:not(.disabled):hover {
      text-decoration: underline;
      color: inherit;
    }

    &.disabled {
      opacity: .5;
      text-decoration: none;
      color: inherit;
      cursor: not-allowed;
    }
  }

  svg path {
    fill: currentColor;
  }

  svg line,
  svg rect {
    stroke: currentColor;
  }

  .icon {
    display: block;
    position: absolute;
    top: 0;
    left: -1px;
    pointer-events: none;
  }
}
