table.months {
  width: 224px;

  td {
    text-align: center;
    font-size: 15px;
  }

  td .button {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 8px 0;
  }

  .button.selected {
    background: var(--dp-selected) !important;
  }

  .button.disabled {
    color: var(--dp-disabled-text);
    cursor: default;
  }

  .button:not(.disabled):hover {
    background: var(--dp-hover);
  }
}
